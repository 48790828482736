import { User } from './user';
import { AircraftCategory } from './aircraft-category';
import { LegRequest } from './leg-request';
import { Operator } from './operator';
import { Status } from './status';
import { PriceTypeEnum } from './price-type-enum';
import { OfferBidStatusEnum } from './offer-bid-status-enum';
import { Aircraft } from './aircraft';
import { OfferModerationHistoryItem } from './offer-moderation-history-item';
import { OfferTypeEnum } from './offer-type-enum';
import { BidHistoryItem } from './bid-history-item';
import { CurrencyEnum } from '../../../../shared/models';
import { LegAirportChanges } from './leg-airport-changes';
import { ElacPriceInfo } from './elac-price-info';
import { CreationTypeEnum } from './creation-type-enum';
import { EmailProcessingMetaData } from './email-processing-meta-data';
import { CancellationTermsTypeEnum } from './cancellation-terms-type-enum';

export class OperatorPurchaseOffer {
  sourcingRequestId: string;
  parentSourcingRequestId: string = null;
  offerPrice: number = null;
  createdUser: User;
  aircraftId: number;
  aircraftCategory: AircraftCategory;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  createdDate: Date;
  expirationDate: Date;
  legs: Array<LegRequest>;
  purchaseOfferId: string;
  operatorPurchaseOfferId: string;
  operator: Operator;
  status: Status;
  acceptedDate?: Date;
  acceptedBy?: User;
  declinedDate?: Date;
  declinedBy?: User;
  cancelledDate?: Date;
  cancelledBy?: User;
  takenOnFleetDate?: Date;
  takenOnFleetBy?: User;
  bidApprovedBy?: User;
  priceType: PriceTypeEnum = null;
  type: OfferTypeEnum = null;
  flightPrice: number;
  commission: number;
  offerStatus?: OfferBidStatusEnum = null;
  aircraft?: Aircraft = null;
  aircraftCategoryIds?: Array<number>;
  aircraftCategories: Array<AircraftCategory>;
  moderationHistory: Array<OfferModerationHistoryItem>;
  quoteReferenceId: string;
  bidHistory: Array<BidHistoryItem>;
  charterAgreementPDF: string;
  additionalInstructions: string;
  ownerAircraftApprovalAcquired: boolean = null;
  currency: CurrencyEnum = null;
  offerPriceInCurrency: number = null;
  currencyUpdateTime?: Date = null;
  currencyRate?: number = null;
  legAirportChanges: Array<LegAirportChanges> = null;
  firmFlexDeparture: boolean = null;
  firmFlexDepartureFee: number = null;
  onlyDepTimeChanged: boolean;
  elacPriceInfo: ElacPriceInfo = null;
  creationType: CreationTypeEnum;
  offerComment: string;
  emailProcessingMetaData: EmailProcessingMetaData = null;
  explorerGuaranteedQuote: boolean = null
  acceptOperatorCancellationTerms: string;
  cancellationTermsType: CancellationTermsTypeEnum;

  getPurchaseOfferId(): string {
    return this.purchaseOfferId;
  }

  setPurchaseOfferId(purchaseOfferId: string): OperatorPurchaseOffer {
    this.purchaseOfferId = purchaseOfferId;
    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): OperatorPurchaseOffer {
    this.operator = operator;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(expirationDate: Date): OperatorPurchaseOffer {
    this.expirationDate = expirationDate;
    return this;
  }

  getOperatorPurchaseOfferId(): string {
    return this.operatorPurchaseOfferId;
  }

  setOperatorPurchaseOfferId(operatorPurchaseOfferId: string): OperatorPurchaseOffer {
    this.operatorPurchaseOfferId = operatorPurchaseOfferId;
    return this;
  }

  getSourcingRequestId(): string {
    return this.sourcingRequestId;
  }

  setSourcingRequestId(sourcingRequestId: string): OperatorPurchaseOffer {
    this.sourcingRequestId = sourcingRequestId;
    return this;
  }

  getParentSourcingRequestId(): string {
    return this.parentSourcingRequestId;
  }

  setParentSourcingRequestId(parentSourcingRequestId: string): OperatorPurchaseOffer {
    this.parentSourcingRequestId = parentSourcingRequestId;
    return this;
  }

  getAircraftCategory(): AircraftCategory {
    return this.aircraftCategory;
  }

  setAircraftCategory(aircraftCategory: AircraftCategory): OperatorPurchaseOffer {
    this.aircraftCategory = aircraftCategory;

    return this;
  }

  getOfferPrice(): number {
    return this.offerPrice;
  }

  setOfferPrice(maxPrice: number): OperatorPurchaseOffer {
    this.offerPrice = maxPrice;
    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): OperatorPurchaseOffer {
    this.legs = legs;
    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): OperatorPurchaseOffer {
    this.lastModifiedDate = lastModifiedDate;
    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): OperatorPurchaseOffer {
    this.lastModifiedUser = lastModifiedUser;
    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): OperatorPurchaseOffer {
    this.createdDate = createdDate;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): OperatorPurchaseOffer {
    this.createdUser = createdUser;
    return this;
  }

  getStatus(): Status {
    return this.status;
  }

  setStatus(status: Status): OperatorPurchaseOffer {
    this.status = status;
    return this;
  }

  getAircraftId(): number {
    return this.aircraftId;
  }

  setAircraftId(aircraftId: number): OperatorPurchaseOffer {
    this.aircraftId = aircraftId;
    return this;
  }

  getAcceptedDate(): Date {
    return this.acceptedDate;
  }

  setAcceptedDate(acceptedDate: Date): OperatorPurchaseOffer {
    this.acceptedDate = acceptedDate;
    return this;
  }

  getAcceptedBy(): User {
    return this.acceptedBy;
  }

  setAcceptedBy(acceptedBy: User): OperatorPurchaseOffer {
    this.acceptedBy = acceptedBy;
    return this;
  }

  getDeclinedDate(): Date {
    return this.declinedDate;
  }

  setDeclinedDate(declinedDate: Date): OperatorPurchaseOffer {
    this.declinedDate = declinedDate;
    return this;
  }

  getDeclinedBy(): User {
    return this.declinedBy;
  }

  setDeclinedBy(declinedBy: User): OperatorPurchaseOffer {
    this.declinedBy = declinedBy;
    return this;
  }

  getCancelledDate(): Date {
    return this.cancelledDate;
  }

  setCancelledDate(cancelledDate: Date): OperatorPurchaseOffer {
    this.cancelledDate = cancelledDate;
    return this;
  }

  getCancelledBy(): User {
    return this.cancelledBy;
  }

  setCancelledBy(cancelledBy: User): OperatorPurchaseOffer {
    this.cancelledBy = cancelledBy;
    return this;
  }

  getTakenOnFleetDate(): Date {
    return this.takenOnFleetDate;
  }

  setTakenOnFleetDate(takenOnFleetDate: Date): OperatorPurchaseOffer {
    this.takenOnFleetDate = takenOnFleetDate;
    return this;
  }

  getTakenOnFleetBy(): User {
    return this.takenOnFleetBy;
  }

  setTakenOnFleetBy(takenOnFleetBy: User): OperatorPurchaseOffer {
    this.takenOnFleetBy = takenOnFleetBy;
    return this;
  }

  getBidApprovedBy(): User {
    return this.bidApprovedBy;
  }

  setBidApprovedBy(bidApprovedBy: User): OperatorPurchaseOffer {
    this.bidApprovedBy = bidApprovedBy;
    return this;
  }

  getPriceType(): PriceTypeEnum {
    return this.priceType;
  }

  setPriceType(priceType: PriceTypeEnum): OperatorPurchaseOffer {
    this.priceType = priceType;
    return this;
  }

  getType(): OfferTypeEnum {
    return this.type;
  }

  setType(type: OfferTypeEnum): OperatorPurchaseOffer {
    this.type = type;
    return this;
  }

  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): OperatorPurchaseOffer {
    this.flightPrice = flightPrice;
    return this;
  }

  getCommission(): number {
    return this.commission;
  }

  setCommission(commission: number): OperatorPurchaseOffer {
    this.commission = commission;
    return this;
  }

  getOfferStatus(): OfferBidStatusEnum {
    return this.offerStatus;
  }

  setOfferStatus(offerStatus: OfferBidStatusEnum): OperatorPurchaseOffer {
    this.offerStatus = offerStatus;
    return this;
  }

  getAircraft(): Aircraft {
    return this.aircraft;
  }

  setAircraft(aircraft: Aircraft): OperatorPurchaseOffer {
    this.aircraft = aircraft;
    return this;
  }

  getAircraftModelName(): string {
    if (this.aircraft && this.aircraft.model && this.aircraft.model.name) {
      return this.aircraft.model.name;
    }
  }

  getAircraftCategoryIds(): Array<number> {
    return this.aircraftCategoryIds;
  }

  setAircraftCategoryIds(aircraftCategoryIds: Array<number>): OperatorPurchaseOffer {
    this.aircraftCategoryIds = aircraftCategoryIds;

    return this;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  setAircraftCategories(categories: Array<AircraftCategory>): OperatorPurchaseOffer {
    this.aircraftCategories = categories;
    return this;
  }

  getAircraftCategoryNames(): string {
    if (this.aircraftCategories && this.aircraftCategories.length) {
      return this.aircraftCategories.map((category) => category.name).join(', ');
    } else {
      return null;
    }
  }

  getModerationHistory(): Array<OfferModerationHistoryItem> {
    return this.moderationHistory;
  }

  setModerationHistory(moderationHistory: Array<OfferModerationHistoryItem>): OperatorPurchaseOffer {
    this.moderationHistory = moderationHistory;
    return this;
  }

  getQuoteReferenceId(): string {
    return this.quoteReferenceId;
  }

  setQuoteReferenceId(quoteReferenceId: string): OperatorPurchaseOffer {
    this.quoteReferenceId = quoteReferenceId;
    return this;
  }

  getBidHistory(): Array<BidHistoryItem> {
    return this.bidHistory;
  }

  setBidHistory(bidHistory: Array<BidHistoryItem>): OperatorPurchaseOffer {
    this.bidHistory = bidHistory;
    return this;
  }

  getCharterAgreementPDF(): string {
    return this.charterAgreementPDF;
  }

  setCharterAgreementPDF(charterAgreementPDF: string): OperatorPurchaseOffer {
    this.charterAgreementPDF = charterAgreementPDF;

    return this;
  }

  getAdditionalInstructions(): string {
    return this.additionalInstructions;
  }

  setAdditionalInstructions(additionalInstructions: string): OperatorPurchaseOffer {
    this.additionalInstructions = additionalInstructions;

    return this;
  }

  getOwnerAircraftApprovalAcquired(): boolean {
    return this.ownerAircraftApprovalAcquired;
  }

  setOwnerAircraftApprovalAcquired(ownerAircraftApprovalAcquired: boolean): OperatorPurchaseOffer {
    this.ownerAircraftApprovalAcquired = ownerAircraftApprovalAcquired;

    return this;
  }

  getOfferPriceInCurrency(): number {
    return this.offerPriceInCurrency;
  }

  setOfferPriceInCurrency(price: number): OperatorPurchaseOffer {
    this.offerPriceInCurrency = price;
    return this;
  }

  getCurrency(): CurrencyEnum {
    return this.currency;
  }

  setCurrency(currency: CurrencyEnum): OperatorPurchaseOffer {
    this.currency = currency;
    return this;
  }

  setCurrencyUpdateTime(updateDate: Date): OperatorPurchaseOffer {
    this.currencyUpdateTime = updateDate;
    return this;
  }

  getCurrencyUpdateTime(): Date {
    return this.currencyUpdateTime;
  }

  getCurrencyRate(): number {
    return this.currencyRate;
  }

  setCurrencyRate(rate: number): OperatorPurchaseOffer {
    this.currencyRate = rate;
    return this;
  }

  getLegAirportChanges(): Array<LegAirportChanges> {
    return this.legAirportChanges;
  }

  setLegAirportChanges(legAirportChanges: Array<LegAirportChanges>): OperatorPurchaseOffer {
    this.legAirportChanges = legAirportChanges;
    return this;
  }

  getFirmFlexDepartureFee(): number {
    return this.firmFlexDepartureFee;
  }

  setFirmFlexDepartureFee(fee: number): OperatorPurchaseOffer {
    this.firmFlexDepartureFee = fee;
    return this;
  }

  getFirmFlexDeparture(): boolean {
    return this.firmFlexDeparture;
  }

  setFirmFlexDeparture(value: boolean): OperatorPurchaseOffer {
    this.firmFlexDeparture = value;
    return this;
  }

  getOnlyDepTimeChanged(): boolean {
    return this.onlyDepTimeChanged;
  }

  setOnlyDepTimeChanged(value: boolean): OperatorPurchaseOffer {
    this.onlyDepTimeChanged = value;
    return this;
  }

  getElacPriceInfo(): ElacPriceInfo {
    return this.elacPriceInfo;
  }

  setElacPriceInfo(elacPriceInfo: ElacPriceInfo): OperatorPurchaseOffer {
    this.elacPriceInfo = elacPriceInfo;

    return this;
  }

  getCreationType(): CreationTypeEnum {
    return this.creationType;
  }

  setCreationType(creationType: CreationTypeEnum): OperatorPurchaseOffer {
    this.creationType = creationType;

    return this;
  }

  getOfferComment(): string {
    return this.offerComment;
  }

  setOfferComment(value: string): OperatorPurchaseOffer {
    this.offerComment = value;
    return this;
  }

  getEmailProcessingMetaData(): EmailProcessingMetaData {
    return this.emailProcessingMetaData;
  }

  setEmailProcessingMetaData(emailProcessingMetaData: EmailProcessingMetaData): OperatorPurchaseOffer {
    this.emailProcessingMetaData = emailProcessingMetaData;

    return this;
  }

  getExplorerGuaranteedQuote(): boolean {
    return this.explorerGuaranteedQuote;
  }

  setExplorerGuaranteedQuote(explorerGuaranteedQuote: boolean): OperatorPurchaseOffer {
    this.explorerGuaranteedQuote = explorerGuaranteedQuote;
    return this;
  }

  getAcceptOperatorCancellationTerms(): string {
    return this.acceptOperatorCancellationTerms;
  }

  setAcceptOperatorCancellationTerms(acceptOperatorCancellationTerms: string): OperatorPurchaseOffer {
    this.acceptOperatorCancellationTerms = acceptOperatorCancellationTerms;
    return this;
  }

  getCancellationTermsType(): CancellationTermsTypeEnum {
    return this.cancellationTermsType;
  }

  setCancellationTermsType(cancellationTermsType: CancellationTermsTypeEnum): OperatorPurchaseOffer {
    this.cancellationTermsType = cancellationTermsType;
    return this;
  }
}
