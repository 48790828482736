import { createSelector } from 'reselect';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromAmenityCollection from './amenity-collection';
import * as fromAircraftCategoriesCollection from './aircraft-categories-collection';
import * as fromAircraftCollection from './aircraft-collection';
import * as fromAirportCollection from './airport-collection';
import * as fromMarketCollection from './market-collection';
import * as fromAreaCollection from './area-collection';
import * as fromRegionCollection from './region-collection';
import * as fromVendorCollection from './vendor-collection';
import * as fromPartnerCollection from './partner-collection';

import * as fromAircraftDetail from './aircraft-detail';
import * as fromAirportDetail from './airport-detail';
import * as fromMarketDetail from './market-detail';
import * as fromAreaDetail from './area-detail';
import * as fromRegionDetail from './region-detail';
import * as fromVendorDetail from './vendor-detail';
import * as fromOperatorDetail from './operator-detail';
import * as fromOperatorDocuments from './operator-documents';

import * as fromRating from './rating';
import * as fromRamenRegions from './ramen-regions';
import * as fromCountriesCollection from './country-collection-list';
import * as fromWyvern from './wyvern';
import * as fromVendorAircraft from './vendor-aircraft';
import * as fromEquipmentCollection from './equipment-collection';
import * as fromChangeLogCollection from './change-log-collection';
import * as fromDocumentChangeLogCollection from './document-change-log-collection';
import * as fromUserChangeLogCollection from './user-change-log-collection';
import * as fromApplicationConfigsCollection from './application-configs-collection';
import * as fromApplicationConfigDetail from './application-config-detail';
import * as fromIntegrationStatusDetail from './integration-status-detail';
import * as fromOrganizationUsers from './organization-users';
import * as fromOperatorCollection from './operator-collection';
import * as fromSentEmails from './sent-emails';
import * as fromUsersCollection from './users-collection';
import * as fromUserDetail from './user-detail';
import * as fromGroupCollection from './group-collection';
import * as fromDocumentTypes from './document-types'
import * as fromVendorOffersCollection from './vendor-offers-collection';
import * as fromCategoriesCollection from './categories-collection';
import * as fromUserTypes from './user-types';
import * as fromPermissionsCollection from './permissions-collection';

export interface State {
  partnerCollection: fromPartnerCollection.State;
  amenityCollection: fromAmenityCollection.State;
  aircraftCategoriesCollection: fromAircraftCategoriesCollection.State;
  aircraftCollection: fromAircraftCollection.State;
  aircraftDetail: fromAircraftDetail.State;
  airportCollection: fromAirportCollection.State;
  airportDetail: fromAirportDetail.State;
  marketCollection: fromMarketCollection.State;
  marketDetail: fromMarketDetail.State;
  areaCollection: fromAreaCollection.State;
  areaDetail: fromAreaDetail.State;
  ramenRegions: fromRamenRegions.State;
  regionCollection: fromRegionCollection.State;
  regionDetail: fromRegionDetail.State;
  vendorCollection: fromVendorCollection.State;
  vendorDetail: fromVendorDetail.State;
  operatorDetail: fromOperatorDetail.State;
  operatorDocuments: fromOperatorDocuments.State;
  rating: fromRating.State;
  countriesCollection: fromCountriesCollection.State;
  wyvern: fromWyvern.State;
  vendorAircraft: fromVendorAircraft.State;
  equipmentCollection: fromEquipmentCollection.State;
  changeLogCollection: fromChangeLogCollection.State;
  documentChangeLogCollection: fromDocumentChangeLogCollection.State;
  userChangeLogCollection: fromUserChangeLogCollection.State;
  applicationConfigsCollection: fromApplicationConfigsCollection.State;
  applicationConfigDetail: fromApplicationConfigDetail.State;
  integrationStatusDetail: fromIntegrationStatusDetail.State;
  organizationUsers: fromOrganizationUsers.State;
  operatorCollection: fromOperatorCollection.State;
  sentEmails: fromSentEmails.State;
  usersCollection: fromUsersCollection.State;
  userDetail: fromUserDetail.State;
  groupCollection: fromGroupCollection.State;
  documentTypes: fromDocumentTypes.State;
  vendorOffersCollection: fromVendorOffersCollection.State;
  categoriesCollection: fromCategoriesCollection.State;
  userTypes: fromUserTypes.State;
  permissionsCollection: fromPermissionsCollection.State;
}

const reducers: ActionReducerMap<State> = {
  partnerCollection: fromPartnerCollection.reducer,
  amenityCollection: fromAmenityCollection.reducer,
  aircraftCategoriesCollection: fromAircraftCategoriesCollection.reducer,
  aircraftCollection: fromAircraftCollection.reducer,
  aircraftDetail: fromAircraftDetail.reducer,
  airportCollection: fromAirportCollection.reducer,
  airportDetail: fromAirportDetail.reducer,
  marketCollection: fromMarketCollection.reducer,
  marketDetail: fromMarketDetail.reducer,
  areaCollection: fromAreaCollection.reducer,
  areaDetail: fromAreaDetail.reducer,
  ramenRegions: fromRamenRegions.reducer,
  regionCollection: fromRegionCollection.reducer,
  regionDetail: fromRegionDetail.reducer,
  vendorCollection: fromVendorCollection.reducer,
  vendorDetail: fromVendorDetail.reducer,
  operatorDetail: fromOperatorDetail.reducer,
  operatorDocuments: fromOperatorDocuments.reducer,
  rating: fromRating.reducer,
  countriesCollection: fromCountriesCollection.reducer,
  wyvern: fromWyvern.reducer,
  vendorAircraft: fromVendorAircraft.reducer,
  equipmentCollection: fromEquipmentCollection.reducer,
  changeLogCollection: fromChangeLogCollection.reducer,
  documentChangeLogCollection: fromDocumentChangeLogCollection.reducer,
  userChangeLogCollection: fromUserChangeLogCollection.reducer,
  applicationConfigsCollection: fromApplicationConfigsCollection.reducer,
  applicationConfigDetail: fromApplicationConfigDetail.reducer,
  integrationStatusDetail: fromIntegrationStatusDetail.reducer,
  organizationUsers: fromOrganizationUsers.reducer,
  operatorCollection: fromOperatorCollection.reducer,
  sentEmails: fromSentEmails.reducer,
  usersCollection: fromUsersCollection.reducer,
  userDetail: fromUserDetail.reducer,
  groupCollection: fromGroupCollection.reducer,
  documentTypes: fromDocumentTypes.reducer,
  vendorOffersCollection: fromVendorOffersCollection.reducer,
  categoriesCollection: fromCategoriesCollection.reducer,
  userTypes: fromUserTypes.reducer,
  permissionsCollection: fromPermissionsCollection.reducer,
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): State {
  return combinedReducer(state, action);
}

export const getPartnerCollectionState = (state: any) => state.resourceMgmt.partnerCollection;
export const getPartnerCollectionIsLoaded = createSelector(
  getPartnerCollectionState,
  fromPartnerCollection.getIsLoaded
);
export const getPartnerCollectionIsLoading = createSelector(
  getPartnerCollectionState,
  fromPartnerCollection.getIsLoading
);
export const getPartnerCollectionItems = createSelector(
  getPartnerCollectionState,
  fromPartnerCollection.getItems
);

export const getAmenityCollectionState = (state: any) => state.resourceMgmt.amenityCollection;
export const getAmenityCollectionIsLoaded = createSelector(
  getAmenityCollectionState,
  fromAmenityCollection.getIsLoaded
);
export const getAmenityCollectionIsLoading = createSelector(
  getAmenityCollectionState,
  fromAmenityCollection.getIsLoading
);
export const getAmenityCollectionItems = createSelector(
  getAmenityCollectionState,
  fromAmenityCollection.getItems
);

export const getAircraftCollectionState = (state: any) => state.resourceMgmt.aircraftCollection;
export const getAircraftCollectionIsLoaded = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getIsLoaded
);
export const getAircraftCollectionIsLoading = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getIsLoading
);
export const getAircraftCollectionItems = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getItems
);
export const getAircraftCollectionSelectedItems = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getSelectedItems
);
export const getAircraftCollectionLookup = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getLookup
);
export const getAircraftCollectionImportStatusIsLoading = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getImportStatusIsLoading
);
export const getAircraftCollectionImportStatusIsLoaded = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getImportStatusIsLoaded
);
export const getAircraftCollectionImportStatus = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getImportStatus
);

export const getAircraftDetailState = (state: any) => state.resourceMgmt.aircraftDetail;
export const getAircraftDetailIsLoaded = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getIsLoaded
);
export const getAircraftDetailIsLoading = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getIsLoading
);
export const getAircraftDetailItem = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getItem
);
export const getAircraftDetailIsPristine = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getPristine
);
export const getAircraftDetailEditMode = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getEditMode
);
export const getAircraftDetailEditActionNew = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getEditActionNew
);
export const getAircraftWyvernData = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getWyvernData
);
export const getAircraftWyvernDataLoaded = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getWyvernDataLoaded
)
export const getAircraftWyvernDataLoading = createSelector(
  getAircraftDetailState,
  fromAircraftDetail.getWyvernDataLoading
);


export const getAirportCollectionState = (state: any) => state.resourceMgmt.airportCollection;
export const getAirportCollectionIsLoaded = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getIsLoaded
);
export const getAirportCollectionIsLoading = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getIsLoading
);
export const getAirportCollectionItems = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getItems
);
export const getAirportCollectionLookup = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getLookup
);

export const getAirportDetailState = (state: any) => state.resourceMgmt.airportDetail;
export const getAirportDetailIsLoaded = createSelector(
  getAirportDetailState,
  fromAirportDetail.getIsLoaded
);
export const getAirportDetailIsLoading = createSelector(
  getAirportDetailState,
  fromAirportDetail.getIsLoading
);
export const getAirportDetailItem = createSelector(
  getAirportDetailState,
  fromAirportDetail.getItem
);

export const getMarketCollectionState = (state: any) => state.resourceMgmt.marketCollection;
export const getMarketCollectionIsLoaded = createSelector(
  getMarketCollectionState,
  fromMarketCollection.getIsLoaded
);
export const getMarketCollectionIsLoading = createSelector(
  getMarketCollectionState,
  fromMarketCollection.getIsLoading
);
export const getMarketCollectionItems = createSelector(
  getMarketCollectionState,
  fromMarketCollection.getItems
);
export const getMarketCollectionIsPristine = createSelector(
  getMarketCollectionState,
  fromMarketCollection.getPristine
);
export const getMarketCollectionLookup = createSelector(
  getMarketCollectionState,
  fromMarketCollection.getLookup
);

export const getMarketDetailState = (state: any) => state.resourceMgmt.marketDetail;
export const getMarketDetailIsLoaded = createSelector(
  getMarketDetailState,
  fromMarketDetail.getIsLoaded
);
export const getMarketDetailIsLoading = createSelector(
  getMarketDetailState,
  fromMarketDetail.getIsLoading
);
export const getMarketDetailItem = createSelector(getMarketDetailState, fromMarketDetail.getItem);
export const getMarketDetailPristine = createSelector(
  getMarketDetailState,
  fromMarketDetail.getPristine
);

export const getAreaCollectionState = (state: any) => state.resourceMgmt.areaCollection;
export const getAreaCollectionIsLoaded = createSelector(
  getAreaCollectionState,
  fromAreaCollection.getIsLoaded
);
export const getAreaCollectionIsLoading = createSelector(
  getAreaCollectionState,
  fromAreaCollection.getIsLoading
);
export const getAreaCollectionItems = createSelector(
  getAreaCollectionState,
  fromAreaCollection.getItems
);
export const getAreaCollectionIsPristine = createSelector(
  getAreaCollectionState,
  fromAreaCollection.getPristine
);
export const getAreaCollectionLookup = createSelector(
  getAreaCollectionState,
  fromAreaCollection.getLookup
);

export const getAreaDetailState = (state: any) => state.resourceMgmt.areaDetail;
export const getAreaDetailIsLoaded = createSelector(getAreaDetailState, fromAreaDetail.getIsLoaded);
export const getAreaDetailIsLoading = createSelector(
  getAreaDetailState,
  fromAreaDetail.getIsLoading
);
export const getAreaDetailItem = createSelector(getAreaDetailState, fromAreaDetail.getItem);
export const getAreaDetailPristine = createSelector(getAreaDetailState, fromAreaDetail.getPristine);

export const getRegionCollectionState = (state: any) => state.resourceMgmt.regionCollection;
export const getRegionCollectionIsLoaded = createSelector(
  getRegionCollectionState,
  fromRegionCollection.getIsLoaded
);
export const getRegionCollectionIsLoading = createSelector(
  getRegionCollectionState,
  fromRegionCollection.getIsLoading
);
export const getRegionCollectionItems = createSelector(
  getRegionCollectionState,
  fromRegionCollection.getItems
);
export const getRegionCollectionIsPristine = createSelector(
  getRegionCollectionState,
  fromRegionCollection.getPristine
);
export const getRegionCollectionLookup = createSelector(
  getRegionCollectionState,
  fromRegionCollection.getLookup
);

export const getRegionDetailState = (state: any) => state.resourceMgmt.regionDetail;
export const getRegionDetailIsLoaded = createSelector(
  getRegionDetailState,
  fromRegionDetail.getIsLoaded
);
export const getRegionDetailIsLoading = createSelector(
  getRegionDetailState,
  fromRegionDetail.getIsLoading
);
export const getRegionDetailItem = createSelector(getRegionDetailState, fromRegionDetail.getItem);
export const getRegionDetailPristine = createSelector(
  getRegionDetailState,
  fromRegionDetail.getPristine
);

export const getVendorCollectionState = (state: any) => state.resourceMgmt.vendorCollection;
export const getVendorCollectionIsLoaded = createSelector(
  getVendorCollectionState,
  fromVendorCollection.getIsLoaded
);
export const getVendorCollectionIsLoading = createSelector(
  getVendorCollectionState,
  fromVendorCollection.getIsLoading
);
export const getVendorCollectionItems = createSelector(
  getVendorCollectionState,
  fromVendorCollection.getItems
);
export const getVendorCollectionLookup = createSelector(
  getVendorCollectionState,
  fromVendorCollection.getLookup
);
export const getVendorCollectionTotalItems = createSelector(
  getVendorCollectionState,
  fromVendorCollection.getTotalItems
);

export const getVendorDetailState = (state: any) => state.resourceMgmt.vendorDetail;
export const getVendorDetailIsLoaded = createSelector(
  getVendorDetailState,
  fromVendorDetail.getIsLoaded
);
export const getVendorDetailIsLoading = createSelector(
  getVendorDetailState,
  fromVendorDetail.getIsLoading
);
export const getVendorDetailItem = createSelector(getVendorDetailState, fromVendorDetail.getItem);
export const getVendorDetailIsPristine = createSelector(
  getVendorDetailState,
  fromVendorDetail.getPristine
);
export const getVendorDetailIsEditMode = createSelector(
  getVendorDetailState,
  fromVendorDetail.getIsEditMode
);

export const getVendorDetailValidationMessage = createSelector(getVendorDetailState, fromVendorDetail.getValidationMessage);
export const getVendorDetailValidated = createSelector(getVendorDetailState, fromVendorDetail.getValidated);

export const getOperatorDetailState = (state: any) => state.resourceMgmt.operatorDetail;
export const getOperatorDetailIsLoaded = createSelector(
  getOperatorDetailState,
  fromOperatorDetail.getIsLoaded
);
export const getOperatorDetailIsLoading = createSelector(
  getOperatorDetailState,
  fromOperatorDetail.getIsLoading
);
export const getOperatorDetailItem = createSelector(getOperatorDetailState, fromOperatorDetail.getItem);

export const getRatingState = (state: any) => state.resourceMgmt.rating;
export const getRatingIsLoaded = createSelector(getRatingState, fromRating.getIsLoaded);
export const getRatingIsLoading = createSelector(getRatingState, fromRating.getIsLoading);
export const getRatingEntity = createSelector(getRatingState, fromRating.getEntity);

export const getOperatorDocumentsState = (state: any) => state.resourceMgmt.operatorDocuments;
export const getOperatorDocuments = createSelector(
  getOperatorDocumentsState,
  fromOperatorDocuments.getItems
);
export const getOperatorDocumentsIsLoaded = createSelector(
  getOperatorDocumentsState,
  fromOperatorDocuments.getIsLoaded
);
export const getOperatorDocumentsIsLoading = createSelector(
  getOperatorDocumentsState,
  fromOperatorDocuments.getIsLoading
);

export const getOrganizationUsersState = (state: any) => state.resourceMgmt.organizationUsers;
export const getOrganizationUsers = createSelector(
  getOrganizationUsersState,
  fromOrganizationUsers.getUsers
);
export const getOrganizationUsersIsLoaded = createSelector(
  getOrganizationUsersState,
  fromOrganizationUsers.getIsLoaded
);
export const getOrganizationUsersIsLoading = createSelector(
  getOrganizationUsersState,
  fromOrganizationUsers.getIsLoading
);

export const getOrganizationUsersLookup = createSelector(
  getOrganizationUsersState,
  fromOrganizationUsers.getLookup
);

export const getOrganizationUsersSelectedUser = createSelector(
  getOrganizationUsersState,
  fromOrganizationUsers.getSelectedUsers
);

export const getRamenRegionsState = (state: any) => state.resourceMgmt.ramenRegions;
export const getRamenRegionsIsLoaded = createSelector(
  getRamenRegionsState,
  fromRamenRegions.getIsLoaded
);
export const getRamenRegionsIsLoading = createSelector(
  getRamenRegionsState,
  fromRamenRegions.getIsLoading
);
export const getRamenRegionsItems = createSelector(getRamenRegionsState, fromRamenRegions.getItems);

export const getAircraftCategoriesCollectionState = (state: any) =>
  state.resourceMgmt.aircraftCategoriesCollection;
export const getAircraftCategoriesCollectionIsLoaded = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getIsLoaded
);
export const getAircraftCategoriesCollectionIsLoading = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getIsLoading
);
export const getAircraftCategoriesCollection = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getCategories
);

export const getCountriesCollectionState = (state: any) => state.resourceMgmt.countriesCollection;
export const getCountriesCollection = createSelector(
  getCountriesCollectionState,
  fromCountriesCollection.getCountriesCollection
);
export const getCountriesCollectionLoaded = createSelector(
  getCountriesCollectionState,
  fromCountriesCollection.getIsLoaded
);
export const getCountriesCollectionIsLoading = createSelector(
  getCountriesCollectionState,
  fromCountriesCollection.getIsLoading
);

export const getWyvernState = (state: any) => state.resourceMgmt.wyvern;
export const getWyvern = createSelector(
  getWyvernState,
  fromWyvern.getItem
);
export const getWyvernLoaded = createSelector(
  getWyvernState,
  fromWyvern.getIsLoaded
);
export const getWyvernIsLoading = createSelector(
  getWyvernState,
  fromWyvern.getIsLoading
);

export const getVendorsAircraftState = (state: any) => state.resourceMgmt.vendorAircraft;
export const getVendorsAircraft = createSelector(
  getVendorsAircraftState,
  fromVendorAircraft.getItems
);
export const getVendorsAircraftLoaded = createSelector(
  getVendorsAircraftState,
  fromVendorAircraft.getIsLoaded
);
export const getVendorsAircraftIsLoading = createSelector(
  getVendorsAircraftState,
  fromVendorAircraft.getIsLoading
);

export const getEquipmentCollectionState = (state: any) => state.resourceMgmt.equipmentCollection;
export const getEquipmentCollectionIsLoaded = createSelector(
  getEquipmentCollectionState,
  fromEquipmentCollection.getIsLoaded
);
export const getEquipmentCollectionIsLoading = createSelector(
  getEquipmentCollectionState,
  fromEquipmentCollection.getIsLoading
);
export const getEquipmentCollectionItems = createSelector(
  getEquipmentCollectionState,
  fromEquipmentCollection.getItems
);

export const getChangeLogCollectionState = (state: any) => state.resourceMgmt.changeLogCollection;
export const getChangeLogCollectionIsLoaded = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getIsLoaded
);
export const getChangeLogCollectionIsLoading = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getIsLoading
);
export const getChangeLogCollectionItems = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getItems
);
export const getChangeLogCollectionTotalItems = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getTotalItems
);
export const getChangeLogGridState = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getGridState
);
export const getChangeLogType = createSelector(
  getChangeLogCollectionState,
  fromChangeLogCollection.getType
);

export const getDocumentChangeLogCollectionState = (state: any) => state.resourceMgmt.documentChangeLogCollection;
export const getDocumentChangeLogCollectionIsLoadingMap = createSelector(
  getDocumentChangeLogCollectionState,
  fromDocumentChangeLogCollection.getIsLoadingMap
);
export const getDocumentChangeLogCollectionItemsMap = createSelector(
  getDocumentChangeLogCollectionState,
  fromDocumentChangeLogCollection.getItemsMap
);

export const getUserChangeLogCollectionState = (state: any) => state.resourceMgmt.userChangeLogCollection;
export const getUserChangeLogCollectionIsLoadingMap = createSelector(
  getUserChangeLogCollectionState,
  fromUserChangeLogCollection.getIsLoadingMap
);
export const getUserChangeLogCollectionItemsMap = createSelector(
  getUserChangeLogCollectionState,
  fromUserChangeLogCollection.getItemsMap
);


export const getApplicationConfigsCollectionState = (state: any) => state.resourceMgmt.applicationConfigsCollection;
export const getApplicationConfigsCollectionLoading = createSelector(
  getApplicationConfigsCollectionState,
  fromApplicationConfigsCollection.getIsLoading
);
export const getApplicationConfigsCollectionLoaded = createSelector(
  getApplicationConfigsCollectionState,
  fromApplicationConfigsCollection.getIsLoaded
);
export const getApplicationConfigsCollectionItems = createSelector(
  getApplicationConfigsCollectionState,
  fromApplicationConfigsCollection.getItems
);
export const getApplicationConfigsCollectionTotalItems = createSelector(
  getApplicationConfigsCollectionState,
  fromApplicationConfigsCollection.getTotalItems
);
export const getApplicationConfigsCollectionLookup = createSelector(
  getApplicationConfigsCollectionState,
  fromApplicationConfigsCollection.getLookup
);


export const getApplicationConfigDetailState = (state: any) => state.resourceMgmt.applicationConfigDetail;
export const getApplicationConfigDetailIsLoaded = createSelector(
  getApplicationConfigDetailState,
  fromApplicationConfigDetail.getIsLoaded
);
export const getApplicationConfigIsLoading = createSelector(
  getApplicationConfigDetailState,
  fromVendorDetail.getIsLoading
);
export const getApplicationConfigItem = createSelector(
  getApplicationConfigDetailState, 
  fromApplicationConfigDetail.getItem
);
export const getApplicationConfigDetailIsPristine = createSelector(
  getApplicationConfigDetailState,
  fromVendorDetail.getPristine
);
export const getApplicationConfigIsEditMode = createSelector(
  getApplicationConfigDetailState,
  fromVendorDetail.getIsEditMode
);

export const getIntegrationStatusDetailState = (state: any) => state.resourceMgmt.integrationStatusDetail;
export const getIntegrationStatusDetailIsLoaded = createSelector(
  getIntegrationStatusDetailState,
  fromIntegrationStatusDetail.getIsLoaded
);
export const getIntegrationStatusDetailIsLoading = createSelector(
  getIntegrationStatusDetailState,
  fromIntegrationStatusDetail.getIsLoading
);
export const getIntegrationStatusItem = createSelector(
  getIntegrationStatusDetailState, 
  fromIntegrationStatusDetail.getItem
);

export const getOperatorCollectionState = (state: any) => state.resourceMgmt.operatorCollection;
export const getOperatorCollectionLoaded = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getLoaded
);
export const getOperatorCollectionLoading = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getLoading
);
export const getOperatorCollection = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getItems
);

export const getSentEmailsState = (state: any) => state.resourceMgmt.sentEmails;
export const getSentEmailsLoaded = createSelector(getSentEmailsState, fromSentEmails.getLoaded);
export const getSentEmailsLoading = createSelector(getSentEmailsState, fromSentEmails.getLoading);
export const getSentEmailsItems = createSelector(getSentEmailsState, fromSentEmails.getItems);
export const getSentEmailsGridState = createSelector(getSentEmailsState, fromSentEmails.getGridState);
export const getSentEmailsTotalItems = createSelector(getSentEmailsState, fromSentEmails.getTotalItems);
export const getEmailBodyLoaded = createSelector(getSentEmailsState, fromSentEmails.getEmailBodyLoaded);
export const getEmailBodyLoading = createSelector(getSentEmailsState, fromSentEmails.getEmailBodyLoading);
export const getEmailBody = createSelector(getSentEmailsState, fromSentEmails.getEmailBody);

export const getUsersCollectionState = (state: any) => state.resourceMgmt.usersCollection;
export const getUsersCollection = createSelector(
  getUsersCollectionState,
  fromUsersCollection.getUsers
);
export const getUsersCollectionIsLoaded = createSelector(
  getUsersCollectionState,
  fromUsersCollection.getIsLoaded
);
export const getUsersCollectionIsLoading = createSelector(
  getUsersCollectionState,
  fromUsersCollection.getIsLoading
);

export const getUsersCollectionLookup = createSelector(
  getUsersCollectionState,
  fromUsersCollection.getLookup
);

export const getUserDetailState = (state: any) => state.resourceMgmt.userDetail;
export const getUserDetailIsLoaded = createSelector(getUserDetailState, fromUserDetail.getIsLoaded);
export const getUserDetailIsLoading = createSelector(getUserDetailState, fromUserDetail.getIsLoading);
export const getUserDetailItem = createSelector(getUserDetailState, fromUserDetail.getItem);
export const getUserDetailIsPristine = createSelector(getUserDetailState, fromUserDetail.getPristine);
export const getUserDetailIsEditMode = createSelector(getUserDetailState, fromUserDetail.getIsEditMode);

export const getGroupCollectionState = (state: any) => state.resourceMgmt.groupCollection;
export const getGroupCollectionIsLoaded = createSelector(getGroupCollectionState, fromGroupCollection.getIsLoaded);
export const getGroupCollectionIsLoading = createSelector(getGroupCollectionState, fromGroupCollection.getIsLoading);
export const getGroupCollectionItems = createSelector(getGroupCollectionState, fromGroupCollection.getItems);

export const getDocumentTypesState = (state: any) => state.resourceMgmt.documentTypes;
export const getDocumentTypesIsLoaded = createSelector(getDocumentTypesState, fromDocumentTypes.getIsLoaded);
export const getDocumentTypesIsLoading = createSelector(getDocumentTypesState, fromDocumentTypes.getIsLoading);
export const getDocumentTypesItems = createSelector(getDocumentTypesState, fromDocumentTypes.getItems);

export const getVendorsOffersState = (state: any) => state.resourceMgmt.vendorOffersCollection;
export const getVendorsOffers = createSelector(
  getVendorsOffersState,
  fromVendorOffersCollection.getItems
);
export const getVendorsOffersIsLoaded = createSelector(
  getVendorsOffersState,
  fromVendorOffersCollection.getIsLoaded
);
export const getVendorsOffersIsLoading = createSelector(
  getVendorsOffersState,
  fromVendorOffersCollection.getIsLoading
);

export const getVendorsOffersLookup = createSelector(
  getVendorsOffersState,
  fromVendorOffersCollection.getLookup
);


export const getCategoriesCollectionState = (state: any) => state.resourceMgmt.categoriesCollection;
export const getCategoriesCollectionIsLoaded = createSelector(
  getCategoriesCollectionState,
  fromCategoriesCollection.getIsLoaded
);
export const getCategoriesCollectionIsLoading = createSelector(
  getCategoriesCollectionState,
  fromCategoriesCollection.getIsLoading
);
export const getCategoriesCollection = createSelector(
  getCategoriesCollectionState,
  fromCategoriesCollection.getCategories
);

export const getUserTypesState = (state: any) => state.resourceMgmt.userTypes;
export const getUserTypesIsLoaded = createSelector(getUserTypesState, fromUserTypes.getIsLoaded);
export const getUserTypesIsLoading = createSelector(getUserTypesState, fromUserTypes.getIsLoading);
export const getUserTypesItems = createSelector(getUserTypesState, fromUserTypes.getItems);

export const getPermissionsCollectionState = (state: any) => state.resourceMgmt.permissionsCollection;
export const getPermissionsCollectionIsLoaded = createSelector(
  getPermissionsCollectionState,
  fromPermissionsCollection.getIsLoaded
);
export const getPermissionsCollectionIsLoading = createSelector(
  getPermissionsCollectionState,
  fromPermissionsCollection.getIsLoading
);
export const getPermissionsCollectionItems = createSelector(
  getPermissionsCollectionState,
  fromPermissionsCollection.getItems
);