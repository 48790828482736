import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import * as moduleComponents from './components';
import * as moduleDirectives from './directives';
import * as modulePipes from './pipes';

import * as moduleServices from './services';

import { PagesModule } from '../../assets/pages/components/pages.module';
import { pgTabsModule } from '../../assets/pages/components/tabs/tabs.module';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './effects/layout.effects';
import { ApiStatusEffects } from './effects/api-status.effects';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule } from "@progress/kendo-angular-popup";
import { TooltipModule as KendoTooltipModule } from '@progress/kendo-angular-tooltip';
import { AirportEffects } from './effects/airport.effects';
import * as airportContracts from './services/airport/contracts';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    moduleComponents.Auth0HeaderComponent,
    moduleComponents.BrowserNotSupportedComponent,
    moduleComponents.DefaultLayoutComponent,
    moduleComponents.LegacyHeaderComponent,
    moduleComponents.InternalErrorComponent,
    moduleComponents.PageSummaryComponent,
    moduleComponents.PaginationComponent,
    moduleComponents.SidebarComponent,
    moduleComponents.SpinnerComponent,
    moduleComponents.SystemComponent,
    moduleComponents.VersionHistoryComponent,
    moduleComponents.WaitProgressComponent,
    moduleComponents.WireframeComponent,
    moduleComponents.SidepanelComponent,
    moduleComponents.GridDropdownFilterCellComponent,
    moduleComponents.ExpireCountdownComponent,
    moduleComponents.MessageBoxComponent,
    moduleComponents.ViewSwitchComponent,
    moduleDirectives.PopoverContentComponent,
    moduleDirectives.ValidationTooltipDirective,
    moduleDirectives.ClipboardDirective,
    moduleDirectives.InfiniteScrollDirective,
    moduleDirectives.HesitateDirective,
    moduleDirectives.AfterValueChangedDirective,
    moduleDirectives.CurrencyRateDirective,
    moduleDirectives.CurrencyRateContentComponent,
    modulePipes.EnumKeyValuePipe,
    modulePipes.EnvironmentPipe,
    modulePipes.DateWithPostfixPipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.ServiceClassPipe,
    modulePipes.EnumToArrayPipe,
    modulePipes.MinutesToHoursPipe,
    modulePipes.SnakeCaseToTitlePipe,
    modulePipes.TypeofPipe,
    modulePipes.ValueToHtmlListPipe,
    modulePipes.IsNotGdprHiddenPipe,
    modulePipes.JsonParsePipe,
    modulePipes.MsToDuration,
    modulePipes.OfferStatusPipe,
    modulePipes.ViewModePipe,
  ],
  exports: [
    moduleComponents.Auth0HeaderComponent,
    moduleComponents.BrowserNotSupportedComponent,
    moduleComponents.DefaultLayoutComponent,
    moduleComponents.LegacyHeaderComponent,
    moduleComponents.InternalErrorComponent,
    moduleComponents.PageSummaryComponent,
    moduleComponents.PaginationComponent,
    moduleComponents.VersionHistoryComponent,
    moduleComponents.SidebarComponent,
    moduleComponents.SpinnerComponent,
    moduleComponents.SystemComponent,
    moduleComponents.WaitProgressComponent,
    moduleComponents.WireframeComponent,
    moduleComponents.SidepanelComponent,
    moduleComponents.GridDropdownFilterCellComponent,
    moduleComponents.ExpireCountdownComponent,
    moduleComponents.MessageBoxComponent,
    moduleComponents.ViewSwitchComponent,
    moduleDirectives.PopoverContentComponent,
    moduleDirectives.ValidationTooltipDirective,
    moduleDirectives.ClipboardDirective,
    moduleDirectives.InfiniteScrollDirective,
    moduleDirectives.HesitateDirective,
    moduleDirectives.AfterValueChangedDirective,
    moduleDirectives.CurrencyRateDirective,
    moduleDirectives.CurrencyRateContentComponent,
    modulePipes.EnumKeyValuePipe,
    modulePipes.EnvironmentPipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.ServiceClassPipe,
    modulePipes.EnumToArrayPipe,
    modulePipes.DateWithPostfixPipe,
    modulePipes.MinutesToHoursPipe,
    modulePipes.SnakeCaseToTitlePipe,
    modulePipes.TypeofPipe,
    modulePipes.ValueToHtmlListPipe,
    modulePipes.IsNotGdprHiddenPipe,
    modulePipes.JsonParsePipe,
    modulePipes.OfferStatusPipe,
    modulePipes.ViewModePipe,
  ],
  imports: [
    BsDropdownModule.forRoot(),
    EffectsModule.forFeature([LayoutEffects, AirportEffects, ApiStatusEffects]),
    CommonModule,
    PagesModule,
    pgTabsModule,
    DropDownsModule,
    RouterModule,
    KendoTooltipModule,
    PopupModule,
    FontAwesomeModule,
    TooltipModule,
  ],
  providers: [
    modulePipes.OfferStatusPipe,
    modulePipes.EnumKeyValuePipe,
    modulePipes.SafeHtmlPipe,
    modulePipes.DateWithNoTimeZonePipe,
    modulePipes.ServiceClassPipe,
    modulePipes.JsonParsePipe,
    moduleServices.ErrorWebServiceLoggerBridge,
    moduleServices.NotificationService,
    moduleServices.ClipboardService,
    modulePipes.ViewModePipe,
    {
      provide: airportContracts.SERVICE_TOKEN,
      useClass: moduleServices.AirportService
    },
    {
      provide: moduleServices.API_STATUS_SERVICE_TOKEN,
      useClass: moduleServices.ApiStatusService
    },
    {
      provide: moduleServices.GIT_ENV_VARIABLES_SERVICE_TOKEN,
      useClass: moduleServices.GitEnvVariablesService
    }
  ],
  entryComponents: [moduleDirectives.PopoverContentComponent]
})
export class SharedModule {
}
