export class VendorContact {
  firstName: string = null;
  lastName: string = null;
  title: string = null;
  phoneNumber: string = null;
  email: string = null;
  quoteRequestEmail: string = null;
  instantBookingEmail: string = null;
  salesAgentName: string = null;
}
