import * as models from '../../domain/models';
import { CurrencyEnum } from '../../../../shared/models';

export class UpdateOfferFormViewModel {
  aircraftId: number = null;
  offerPrice: number = null;
  legs: Array<models.LegRequest> = null;
  flightPrice?: number = null;
  quoteReferenceId: string = null;
  additionalInstructions: string = null;
  ownerAircraftApprovalAcquired?: boolean = null;
  currency: CurrencyEnum = null;
  firmFlexDepartureFee: number;
  firmFlexDeparture: boolean;
  creationType: models.CreationTypeEnum;
  cancellationTermsType: models.CancellationTermsTypeEnum;

  isValid() {
    return !!this.aircraftId && !!this.offerPrice;
  }
}
